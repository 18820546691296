<template>
  <section class="about__page" style="height: 100%;">
    <div class="container d-flex justify-start align-center">
      <Breadcrumb class="territorio_section_bc-color">
        <template v-slot:breadcrumbLinks>
          <router-link
            tag="p"
            :to="{ name: link.href }"
            v-for="(link, idx) in breadcrumbItems"
            :key="idx"
          >
            {{ link.text }}
          </router-link>
        </template>
      </Breadcrumb>
    </div>

    <v-row class="d-flex justify-md-space-around align-center pt-5 pb-5 ovx">
      <v-col
        cols="3"
        md="1"
        class="d-flex justify-center align-center text-left"
      >
        <router-link :to="{ name: 'sobre_bento_rodrigues' }">
          Sobre
        </router-link>
      </v-col>
      <v-col
        cols="3"
        md="1"
        class="d-flex justify-center align-center text-left"
      >
        <router-link :to="{ name: 'sobre_caracteristica_nucleo_geral' }">
          Características do núcleo urbano
        </router-link>
      </v-col>
      <v-col
        cols="3"
        md="1"
        class="d-flex justify-center align-center text-left"
      >
        <router-link :to="{ name: 'sobre_cultura_bento' }">
          Cultura
        </router-link>
      </v-col>
      <v-col
        cols="3"
        md="1"
        class="d-flex justify-center align-center text-left"
      >
        <router-link :to="{ name: 'sobre_bento_atividade' }">
          Atividades
        </router-link>
      </v-col>
      <v-col
        cols="3"
        md="1"
        class="d-flex justify-center align-center text-left"
      >
        <router-link :to="{ name: 'sobre_bento_rompimento' }">
          O Rompimento
        </router-link>
      </v-col>
      <v-col
        cols="3"
        md="1"
        class="d-flex justify-center align-center text-left"
      >
        <router-link :to="{ name: 'sobre_bento_tradicoes_resistencias' }">
          Tradições e resistência
        </router-link>
      </v-col>
      <v-col
        cols="3"
        md="1"
        class="d-flex justify-center align-center text-left"
      >
        <router-link :to="{ name: 'mapa_territorios' }">
          Mapa
        </router-link>
      </v-col>
    </v-row>
    <transition>
      <router-view />
    </transition>
    <ScrollTop />
  </section>
</template>

<script>
import Breadcrumb from "@/layout/Breadcrumb";
import ScrollTop from "@/components/ScrollTop.vue";

export default {
  components: { Breadcrumb, ScrollTop },
  data() {
    return {
      active: "active",
      breadcrumbItems: [
        {
          text: "< Territórios /",
          href: "mapa_territorios",
        },
        {
          text: " Bento Rodrigues",
          href: "sobre_bento",
        },
      ],
    };
  },
};
</script>

<style scoped>
@media (max-width: 600px) {
  .ovx {
    overflow-y: scroll;
    flex-wrap: nowrap;
    overflow-y: auto;

    margin-bottom: 2em;
  }

  .ovx::-webkit-scrollbar {
    width: 10px;
  }
  .ovx::-webkit-scrollbar-track {
    box-shadow: inset 0 1px rgb(224, 105, 25, 0.6);
    border: 6px solid transparent;
    background-clip: content-box;
    width: 180px;
  }
  .ovx::-webkit-scrollbar-thumb {
    background: rgb(224, 105, 25, 0.8);
    border-radius: 10px;
    height: 4px;
    width: 180px;
  }
}

a {
  color: #5d3f36;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  font-size: 0.8em;
}
.router-link-active {
  cursor: pointer;
  text-decoration: underline;
  text-decoration-color: #e06919;
}
.router-link-exact-active {
  cursor: pointer;
  text-decoration: underline;
  text-decoration-color: #e06919;
}

.router-link {
  color: #5d3f36;
}
</style>
